import { checkAdminRole } from '@/auth/utils';

export default [
    {
        path: '/reporteria',
        name: 'admin-reporteria',
        component: () => import('@/views/modules/reporterias/Reporteria.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Reporteria',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/formularios-completados-por-rubro',
        name: 'admin-reporteria-1',
        component: () => import('@/views/modules/reporterias/FormulariosCompletadosPorRubro.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Formularios Completados Por Rubro',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/formularios-por-estado',
        name: 'admin-reporteria-2',
        component: () => import('@/views/modules/reporterias/FormulariosPorEstado.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Formularios Por Estado',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/formularios-por-nivel',
        name: 'admin-reporteria-3',
        component: () => import('@/views/modules/reporterias/FormulariosPorNivel.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Formularios Por Nivel',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/formularios-por-rubro-y-proceso',
        name: 'admin-reporteria-4',
        component: () => import('@/views/modules/reporterias/FormulariosPorRubroYProceso.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Formularios Por Rubro Y Proceso',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/formularios-por-tarifario',
        name: 'admin-reporteria-5',
        component: () => import('@/views/modules/reporterias/FormulariosPorTarifario.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Formularios Por Tarifario',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/informes-emitidos-por-rubro',
        name: 'admin-reporteria-6',
        component: () => import('@/views/modules/reporterias/InformesEmitidosPorRubro.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Informes Emitidos Por Rubro',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/proveedores-por-nivel',
        name: 'admin-reporteria-7',
        component: () => import('@/views/modules/reporterias/ProveedoresPorNivel.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Proveedores Por Nivel',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/proveedores-por-rubro',
        name: 'admin-reporteria-8',
        component: () => import('@/views/modules/reporterias/ProveedoresPorRubro.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Proveedores Por Rubro',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/analisis-pu',
        name: 'admin-reporteria-9',
        component: () => import('@/views/modules/reporterias/AnalisisPU.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Análisis de PU',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/resultados-rev-lab',
        name: 'admin-reporteria-10',
        component: () => import('@/views/modules/reporterias/ResultadosRevLab.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Resultados Rev. Lab.',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/criticidad-laboral',
        name: 'admin-reporteria-11',
        component: () => import('@/views/modules/reporterias/CriticidadLaboral.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Criticidad Laboral',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/observaciones',
        name: 'admin-reporteria-12',
        component: () => import('@/views/modules/reporterias/Observaciones.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Observaciones',
                active: true
            }]
        }
    }, 
    {
        path: '/reporteria/diferencia-salarial',
        name: 'admin-reporteria-13',
        component: () => import('@/views/modules/reporterias/DiferenciaSalarial.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Estadísitcas y Reportería',
            breadcrumb: [{
                text: 'Diferencia Salarial',
                active: true
            }]
        }
    }
]