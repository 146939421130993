import { checkProviderRole } from '@/auth/utils';

export default [{
        path: '/provider/forms',
        name: 'provider-forms',
        component: () => import ('@/views/modules/provider/forms/forms-list/FormsList.vue'),
        beforeEnter: checkProviderRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Lista de formularios',
                active: true
            }]
        }
    },
    {
        path: '/provider/form/:id/edit',
        name: 'provider-form-edit',
        component: () => import ('@/views/modules/provider/forms/forms-edit/FormsEdit.vue'),
        beforeEnter: checkProviderRole,
        meta: {
            action: 'read',
            resource: 'ACL',
            pageTitle: 'Completar Formulario'
        }
    }
]