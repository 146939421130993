import { checkProviderRole } from '@/auth/utils';

export default [{
		path: '/provider/reports',
		name: 'provider-reports',
		component: () => import ('@/views/modules/provider/reports/reports-list/ReportsList.vue'),
		beforeEnter: checkProviderRole,
		meta: {
			action: 'read',
			resource: 'ACL',
			pageTitle: 'Estadísitcas & Reportes',
			breadcrumb: [{
				text: 'Comparación de Promedio',
				active: true
			}]
		}
	},
]