import { checkAdminRole } from '@/auth/utils';

export default [{
        path: '/provider/:id/forms',
        name: 'providers-forms',
        component: () => import ('@/views/modules/forms/forms-list/FormsList.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            resource: 'ACL',
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Lista de formularios',
                active: true
            }]
        }
    },
    {
        path: '/forms/:id/edit',
        name: 'forms-edit',
        // component: () => import('@/views/modules/forms/forms-edit/FormsEdit.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Editar formulario',
                active: true
            }]
        }
    },
    {
        path: '/forms/:id/view',
        name: 'forms-view',
        component: () => import ('@/views/modules/forms/forms-view/FormsView.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Ver formulario',
                active: true
            }]
        }
    },

]